// Pricing calculator functions
if (document.querySelector('[data-pricing-settings]')) {
  // get settings and global elements
  const container = document.querySelector('[data-pricing-settings]');
  const plans = document.querySelector('[data-pricing-plans]');
  const calculator = document.querySelector('[data-calculator]');
  const form = document.querySelector('[data-calculator-form]');

  // set defaults
  let data = {
    'selectedPlanIndex': 0,
    'paymentPlan': 'monthly',
    'selectedTotalPrice': 0,
    'selectedTotalQuantity': 1,
    'secondaryPlanIndex': 1,
    'secondaryTotalPrice': 0,
    'secondaryTotalQuantity': 0,
    'settings': JSON.parse(container.getAttribute('data-pricing-settings'))
  };

  function showView(view) {
    if (view == 'calculator') {
      plans.classList.add('tw-hidden');
      form.classList.add('tw-hidden');
      calculator.classList.remove('tw-hidden');
    } else if (view == 'form') {
      plans.classList.add('tw-hidden');
      form.classList.remove('tw-hidden');
      calculator.classList.add('tw-hidden');
    } else {
      plans.classList.remove('tw-hidden');
      form.classList.add('tw-hidden');
      calculator.classList.add('tw-hidden');

    }

    window.scrollTo(0, container.offsetTop - document.querySelector('#primary-navigation').offsetHeight);
  }

  function setPaymentPlan(model) {
    data.paymentPlan = model;


    document.querySelectorAll('[data-plan]').forEach(plan => {
      const planIndex = plan.getAttribute('data-plan');
      const planData = data.settings[planIndex][model];
      if (planData.price) {
        plan.querySelector('[data-plan-price]').innerHTML = planData.price;
      }

      if (planData.desc) {
        plan.querySelector('[data-plan-price-desc]').innerHTML = planData.desc;
      }
    });

    // Change descriptions
    document.querySelectorAll('[data-desc]').forEach(descItem => {
      const planDesc = JSON.parse(descItem.getAttribute('data-desc'));
      if (planDesc[model]) {
        descItem.innerHTML = planDesc[model];
      }
    });

    // change the calculator content

    const selectedPlan = data.settings[data.selectedPlanIndex];
    const secondaryPlan = data.settings[data.secondaryPlanIndex];

    // set selected content
    if (calculator.querySelector('[data-calculator-name-selected]')) {
      calculator.querySelector('[data-calculator-name-selected]').innerHTML = selectedPlan.name;
    }

    if (calculator.querySelector('[data-calculator-quantityText-selected]')) {
      calculator.querySelector('[data-calculator-quantityText-selected]').innerHTML = selectedPlan[data.paymentPlan].quantityText;
    }

    if (calculator.querySelector('[data-calculator-desc-selected]')) {
      calculator.querySelector('[data-calculator-desc-selected]').innerHTML = selectedPlan[data.paymentPlan].desc;
    }

    const selectedSubTotal = calculator.querySelector('[data-calculator-subtotal-selected]');
    const selectedQuantity = calculator.querySelector('[data-calculator-quantity="selected"]').querySelector('[data-calculator-quantity-input]').value;
    setPrice(selectedSubTotal, selectedPlan[data.paymentPlan].price, selectedQuantity, selectedPlan.currency);

    data.selectedTotalPrice = parseFloat(selectedSubTotal.textContent.replace(/,/g, '.'));
    data.selectedTotalQuantity = parseFloat(selectedQuantity);

    // set secondary content
    if (calculator.querySelector('[data-calculator-name-secondary]')) {
      calculator.querySelector('[data-calculator-name-secondary]').innerHTML = secondaryPlan.name;
    }
    if (calculator.querySelector('[data-calculator-quantityText-secondary]')) {
      calculator.querySelector('[data-calculator-quantityText-secondary]').innerHTML = secondaryPlan[data.paymentPlan].quantityText;
    }
    if (calculator.querySelectorAll('[data-calculator-desc-secondary]')) {
      calculator.querySelectorAll('[data-calculator-desc-secondary]').forEach(desc => {
        desc.innerHTML = secondaryPlan[data.paymentPlan].desc;
      });
    }

    const secondaryItemPrice = calculator.querySelector('[data-calculator-itemprice-secondary]');
    setPrice(secondaryItemPrice, secondaryPlan[data.paymentPlan].price, 1, selectedPlan.currency);

    const secondarySubTotal = calculator.querySelector('[data-calculator-subtotal-secondary]');
    const secondaryQuantity = calculator.querySelector('[data-calculator-quantity="secondary"]').querySelector('[data-calculator-quantity-input]').value;
    setPrice(secondarySubTotal, secondaryPlan[data.paymentPlan].price, secondaryQuantity, selectedPlan.currency);

    data.secondaryTotalPrice = parseFloat(secondarySubTotal.textContent.replace(/,/g, '.'));
    data.secondaryTotalQuantity = parseFloat(secondaryQuantity);

    // set data
    const monthlyTotal = calculator.querySelector('[data-calculator-total-monthly]');
    let monthlyPrice = data.selectedTotalPrice + data.secondaryTotalPrice;
    monthlyPrice = (data.paymentPlan == 'monthly') ? monthlyPrice : monthlyPrice / 12;
    monthlyPrice = parseFloat(monthlyPrice).toFixed(2).replace('.', ',');
    setPrice(monthlyTotal, monthlyPrice, 1, selectedPlan.currency);

    // Set form values
    form.querySelectorAll('[data-calculator-form-selected-plan-name]').forEach(item => {
      item.innerHTML = selectedPlan.name;
    });
    form.querySelectorAll('[data-calculator-form-total-users]').forEach(item => {
      item.innerHTML = parseFloat(data.selectedTotalQuantity) + parseFloat(data.secondaryTotalQuantity);
    });
    form.querySelectorAll('[data-calculator-form-selected-plan-users]').forEach(item => {
      item.innerHTML = data.selectedTotalQuantity;
    });
    form.querySelectorAll('[data-calculator-form-selected-plan-description]').forEach(item => {
      item.innerHTML = selectedPlan[data.paymentPlan].desc;
    });
    form.querySelectorAll('[data-calculator-form-selected-plan-total]').forEach(item => {
      setPrice(item, data.selectedTotalPrice, 1, selectedPlan.currency);
    });
    form.querySelectorAll('[data-calculator-form-secondary-plan-name]').forEach(item => {
      item.innerHTML = secondaryPlan.name;
    });
    form.querySelectorAll('[data-calculator-form-secondary-plan-users]').forEach(item => {
      item.innerHTML = data.secondaryTotalQuantity;
    });
    form.querySelectorAll('[data-calculator-form-secondary-plan-description]').forEach(item => {
      item.innerHTML = secondaryPlan[data.paymentPlan].desc;
    });
    form.querySelectorAll('[data-calculator-form-secondary-plan-total]').forEach(item => {
      setPrice(item, data.secondaryTotalPrice, 1, secondaryPlan.currency);
    });

    form.querySelectorAll('[data-calculator-form-total-text]').forEach(item => {
      item.innerHTML = item.getAttribute('data-' + data.paymentPlan);
    });

    const price = monthlyPrice;

    form.querySelectorAll('[data-calculator-form-total]').forEach(item => {
      setPrice(item, price, 1, selectedPlan.currency);
    });

    // set data
    const monthlyFormTotal = form.querySelector('[data-form-total-monthly]');
    if (monthlyFormTotal) {
      setPrice(monthlyFormTotal, monthlyPrice, 1, selectedPlan.currency);
    }

    // fill out hidden field with calculator content
    if (form.querySelector("[name='calculatorInformation']")) {
      form.querySelector("[name='calculatorInformation']").value = selectedPlan.name + ': ' + data.selectedTotalQuantity + ', ' + secondaryPlan.name + ': ' + data.secondaryTotalQuantity + ', Payment plan: ' + data.paymentPlan + ' - ' + price + ' ' + selectedPlan.currency;
    }
  }

  // small helper function to set the price in correct format
  function setPrice(element, price, amount, currency) {
    price = price.toString();
    price = price.replace(',', '.');
    price = parseFloat(price);
    price = price * parseFloat(amount);
    price = price.toFixed(2)

    let priceHTML = price.replace('.', ',') + ' ' + currency;

    element.innerHTML = priceHTML;
  }


  // add the back button beside the form button
  const formBackButton = document.querySelector('[data-show-view="calculator"]');
  const formSubmitButton = document.querySelector('button[type="submit"]');
  formSubmitButton.parentNode.insertBefore(formBackButton, formSubmitButton);

  // Checkbox on change
  document.querySelectorAll('[data-calculator-checkbox]').forEach(checkbox => {
    checkbox.addEventListener('change', function () {
      setPaymentPlan(checkbox.value);
    })
  });

  // click event on all plans
  document.querySelectorAll('[data-plan]').forEach(plan => {
    const selectedIndex = plan.getAttribute('data-plan');
    const secondaryIndex = (selectedIndex == 0) ? parseInt(selectedIndex) + 1 : parseInt(selectedIndex) - 1;

    const button = plan.querySelector('[data-show-calculator]');
    if (button) {
      button.addEventListener('click', event => {
        data.selectedPlanIndex = selectedIndex;
        data.secondaryPlanIndex = secondaryIndex;
        setPaymentPlan(data.paymentPlan);
        showView('calculator');
      });
    }
  });

  // Quantity controllers
  document.querySelectorAll('[data-calculator-quantity]').forEach(quantityController => {
    const type = quantityController.getAttribute('data-calculator-quantity');
    const increase = quantityController.querySelector('[data-calculator-quantity-increase]');
    const decrease = quantityController.querySelector('[data-calculator-quantity-decrease]');
    let input = quantityController.querySelector('[data-calculator-quantity-input]');

    increase.addEventListener('click', function () {
      input.value = parseFloat(data[type + 'TotalQuantity']) + 1;
      setPaymentPlan(data.paymentPlan);
    });

    decrease.addEventListener('click', function () {
      input.value = (parseFloat(data[type + 'TotalQuantity']) > 0) ? parseFloat(data[type + 'TotalQuantity']) - 1 : 0;
      setPaymentPlan(data.paymentPlan);
    });

    input.addEventListener('change', function () {
      if (input.value <= 0) {
        input.value = 0;
      }
      setPaymentPlan(data.paymentPlan);
    });

  });

  document.addEventListener('click', function (event) {
    if (!event.target.matches('[data-submit-form]')) return;
    document.querySelector('.form-html form').submit;
  });

  document.querySelectorAll('[data-show-view]').forEach(button => {
    button.addEventListener('click', function () {
      showView(button.getAttribute('data-show-view'));
    });
  });

  var formElement = document.querySelector('form');
  formElement.addEventListener('freeform-render-success', function (event) {
    window.scrollTo(0, container.offsetTop - document.querySelector('#primary-navigation').offsetHeight);
  });
  formElement.addEventListener('freeform-ajax-error', function (event) {
    window.scrollTo(0, container.offsetTop - document.querySelector('#primary-navigation').offsetHeight);
  });
}