// We import our styles here, webpack will do the rest
import styles from '../scss/app.scss';
// we import all other js libraries
import '@babel/polyfill';
import 'picturefill';
import * as Cookies from 'tiny-cookie';
import reframe from './helpers/iframe';
import Vue from 'vue';
import Lazysizes from 'lazysizes';
import { PhotoSwipe, PhotoSwipeGallery } from 'v-photoswipe';
import Flickity from 'flickity';

import './pricing';

Lazysizes.preloadAfterLoad = true;

// responsive iframes
reframe(document.querySelectorAll('.wysiwyg iframe, .embedcode iframe')) // Pass a live node list

const primaryNavigations = document.querySelectorAll('#primary-navigation')

if (primaryNavigations) {
  primaryNavigations.forEach(element => {

    // Primary Navigation
    const primaryNavigation = new Vue({
      name: 'primary-navigation',
      el: element,
      data: {
        isAtTop: true,
        open: false,
        subNavItems: [],
      },
      created() {
        window.addEventListener('scroll', this.handleScroll)
      },
      destroyed() {
        window.removeEventListener('scroll', this.handleScroll)
      },
      methods: {
        handleScroll() {
          if (!this.open) {
            this.isAtTop = window.pageYOffset === 0 ? true : false
          } else {
            this.isAtTop = false
          }
        },
        toggleNavigation() {
          if (!this.open && this.isAtTop === true) {
            this.isAtTop = false
          }
          this.open = !this.open
        },
        toggleSubNav(item) {
          if (this.subNavItems.includes(item)) {
            let indexOfItem = this.subNavItems.indexOf(item)
            this.subNavItems.splice(indexOfItem, 1)
          } else {
            this.subNavItems.push(item)
          }
        },
        subNavMouseEnter(item) {
          if (window.innerWidth >= 992) {
            this.subNavItems.push(item)
          }
        },
        subNavMouseLeave(item) {
          if (window.innerWidth >= 992) {
            this.subNavItems.pop(item)
          }
        },
      },
    })
  })
};

// Footer Navigation
const footerNavigation = new Vue({
  name: 'footer',
  el: '#footer',
  data: {
    subNavItems: [],
  },
  methods: {
    toggleSubNav(item) {
      if (this.subNavItems.includes(item)) {
        let indexOfItem = this.subNavItems.indexOf(item)
        this.subNavItems.splice(indexOfItem, 1)
      } else {
        this.subNavItems.push(item)
      }
    },
  },
})

if (document.getElementById('side-navigation')) {
  // Side Navigation
  const sideNavigation = new Vue({
    name: 'side-navigation',
    el: '#side-navigation',
    data: {
      subNavItems: [],
    },
    created() {
      let activeItems = document.getElementById('side-navigation').dataset
        .active

      this.subNavItems = activeItems.split(', ')
    },
    methods: {
      toggleSubNav(item) {
        if (this.subNavItems.includes(item)) {
          let indexOfItem = this.subNavItems.indexOf(item)
          this.subNavItems.splice(indexOfItem, 1)
        } else {
          this.subNavItems.push(item)
        }
      },
      goTo(url) {
        window.location.href = url
      },
    },
  })
}

// Responsive tables
// wrap tables in wysiwyg in a div for styling
const tables = document.querySelectorAll('.wysiwyg table')
if (tables.length) {
  if (!('length' in tables)) tables = [tables]
  for (let i = 0; i < tables.length; i += 1) {
    const table = tables[i]

    const div = document.createElement('div')
    div.className = 'table-container'

    table.parentNode.insertBefore(div, table)
    table.parentNode.removeChild(table)
    div.appendChild(table)
  }
}

// Galleries
const galleries = document.querySelectorAll('.gallery')

for (let i = 0; i < galleries.length; i += 1) {
  const gallery = galleries[i]
  // Create our vue instance
  new Vue({
    name: 'gallery',
    el: gallery,
    components: {
      'v-photoswipe': PhotoSwipe,
    },
    data: {
      isOpen: false,
      options: {
        index: 0,
        showHideOpacity: true,
        showAnimationDuration: 333,
        hideAnimationDuration: 333,
        bgOpacity: 0.9,
        spacing: 0.12,
        allowPanToNext: true,
        maxSpreadZoom: 2,
        loop: true,
        pinchToClose: true,
        closeOnScroll: false,
        closeOnVerticalDrag: true,
        mouseUsed: false,
        escKey: true,
        arrowKeys: true,
        history: false,
        focus: true,
        modal: true,
      },
      items: [],
    },
    methods: {
      showPhotoSwipe(index) {
        this.isOpen = true
        this.options.index = index
      },
      hidePhotoSwipe() {
        this.isOpen = false
      },
    },
    created() {
      let items = gallery.dataset.items

      this.items = JSON.parse(items)
    },
  })
}

// Accordion
if (document.querySelectorAll('.accordion')) {
  const accordions = document.querySelectorAll('.accordion')

  for (let i = 0; i < accordions.length; i += 1) {
    const accordion = accordions[i]
    // Side Navigation
    const sideNavigation = new Vue({
      name: 'accordion',
      el: accordion,
      data: {
        activeItems: [],
      },
      methods: {
        toggleItem(item) {
          if (this.activeItems.includes(item)) {
            let indexOfItem = this.activeItems.indexOf(item)
            this.activeItems.splice(indexOfItem, 1)
          } else {
            this.activeItems.push(item)
          }
        },
      },
    })
  }
}

function isElementInViewport(el) {
  var rect = el.getBoundingClientRect()
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
    (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

// animate number counter
if (document.querySelectorAll('.animate-counter')) {
  const counters = document.querySelectorAll('.animate-counter')

  for (let i = 0; i < counters.length; i += 1) {
    const counter = counters[i]
    const originalContent = counter.textContent;
    const number = parseInt(counter.textContent)
    let current = 0

    const interval = 1000 / number

    counter.textContent = current

    let animation = window.setInterval(function () {
      if (isElementInViewport(counter)) {
        if (current < number) {
          var change = (number - 0) / 100
          change = change >= 0 ? Math.ceil(change) : Math.floor(change)
          current = current + change
          counter.textContent = current
        } else {
          clearAnimation(counter);
        }
      }
    }, interval);

    function clearAnimation(counter) {
      clearInterval(animation);
      counter.textContent = originalContent;
    }
  }
}

// animate percentage circle
if (document.querySelectorAll('.circular-chart')) {
  const charts = document.querySelectorAll('.circular-chart')

  for (let i = 0; i < charts.length; i += 1) {
    const chart = charts[i]
    let notYet = true

    window.setInterval(function () {
      if (isElementInViewport(chart) && notYet) {
        notYet = false
        chart.classList.remove('loading')
      }
    }, 1000)
  }
}

// Tabs
if (document.querySelectorAll('.tabs')) {
  const tabsElement = document.querySelectorAll('.tabs')

  for (let i = 0; i < tabsElement.length; i += 1) {
    const tabs = tabsElement[i]
    new Vue({
      name: 'tabs-container',
      el: tabs,
      data: {
        active: null,
        css: {
          width: '0px',
          transform: 'translateX(0px)',
        },
      },
      methods: {
        setTab(id) {
          const activeElement = this.$refs[`nav-${id}`]
          const width = activeElement.clientWidth
          const left = activeElement.offsetLeft

          this.active = id
          this.css.width = `${width}px`
          this.css.transform = `translateX(${left}px)`
        },
      },
      mounted() {
        window.addEventListener('load', () => {
          this.setTab(this.$el.attributes['data-active'].value)
        })
      },
    })
  }
}

window.onload = function () {

  // Page has loaded, remove loading class
  document.getElementsByTagName('body')[0].classList.remove('loading')

  function lazyloadVideoEmbeds() {
    const lazyLoadingChild = videoContainer.querySelector(".hero-embed__placeholder");

    videoContainer.removeChild(lazyLoadingChild);

    const iFrame = document.createElement("iframe");
    iFrame.classList.add("lazyload");

    iFrame.src = videoContainer.getAttribute('data-url');

    videoContainer.appendChild(iFrame);

    videoContainer.removeEventListener("mouseover", lazyloadVideoEmbeds);
  }

  // Lazylaod videoembeds
  const videoContainer = document.querySelector(".hero-embed");

  if (videoContainer) {
    videoContainer.addEventListener("mouseover", lazyloadVideoEmbeds);
  }
}

if (document.querySelector('.main-carousel')) {
  // element argument can be a selector string
  //   for an individual element
  var flkty = new Flickity('.main-carousel', {
    wrapAround: true,
    adaptiveHeight: true,
    prevNextButtons: false
  });

  flkty.resize();
}

var headerDropdown = function () {
  "use strict";

  const mainNav = document.querySelector(".main-navigation");
  window.addEventListener('click', (e) => {
    const openMenus = document.querySelectorAll(".main-navigation .has-submenu.open");
    if (!e.target.closest(".main-navigation .has-submenu")) {
      if (openMenus.length) {
        openMenus.forEach(element => {
          element.classList.remove('open');
          mainNav.classList.remove("dropdown-open");
        });
      }
    }
  });

  const allSubmenus = document.querySelectorAll(".main-navigation .has-submenu");
  if (allSubmenus) {
    allSubmenus.forEach(element => {
      element.addEventListener('click', function (event) {
        event.stopImmediatePropagation();

        const target = event.target;

        const openMenus = document.querySelectorAll(".main-navigation .has-submenu.open");
        if (target.classList.contains('top-level-link')) {
          if (openMenus.length) {
            openMenus.forEach(element => {
              if (target.dataset.id !== element.dataset.id) {
                element.classList.remove('open');
                mainNav.classList.remove("dropdown-open");
              }
            });
          }
          if (target.classList.contains('open')) {
            target.classList.remove("open");
            target.parentElement.classList.remove("open");
            mainNav.classList.remove("dropdown-open");
          } else {
            target.classList.add("open");
            target.parentElement.classList.add("open");
            mainNav.classList.add("dropdown-open");
          }
        }
      })

    });
  }
};

var headerSubmenus = function () {
  "use strict";

  const allMenuLevel2 = document.querySelectorAll(".main-navigation .has-submenu .menu-level-2");

  allMenuLevel2.forEach(element => {
    element.addEventListener('click', function (event) {
      event.stopImmediatePropagation();

      const target = event.target;

      const targetParent = target.closest('.product-dropdown')

      const allLevelTwoMenus = targetParent.querySelectorAll('.menu-level-2');
      const allSubmenus = targetParent.querySelectorAll('.list-level-3');

      if (allLevelTwoMenus) {
        allLevelTwoMenus.forEach(element => {
          element.classList.remove('active');
        });
      };

      if (allSubmenus) {
        allSubmenus.forEach(element => {
          element.classList.remove('active');
        });
      };

      if (target.dataset.levelTwoId) {
        const subMenu = document.querySelector(`[data-level-three-parent-id="${target.dataset.levelTwoId}"]`);
        if (subMenu) {
          subMenu.classList.add('active');
          target.classList.add('active');
        }
      }
    })
  })
};

// check if password protection exists and focus on input
if (document.getElementById('password-input')) {
  document.getElementById("password-input").focus();
}


document.addEventListener("DOMContentLoaded", function () {
  "use strict";
  headerDropdown();
  headerSubmenus();
});